* {
    box-sizing: border-box;
}

div, ul, li {
    box-sizing: border-box;
}

/* Global Box Styles */
.MuiBox-root, .MuiPaper-root {
    box-sizing: border-box;
}

/* Change Attributions */
.mapbox-attribution-container .attrition-logo .mapboxgl-ctrl-logo {
    display: none;
}

.mapbox-attribution-container > div:last-child a[href='https://kepler.gl/policy/'] {
    display: none;
}

.mapbox-attribution-container > div:last-child a[href='https://www.mapbox.com/map-feedback/'] {
    display: none;
}

/* On Light Background */
.company-dashboard-body ::-webkit-calendar-picker-indicator {
    filter: invert(0.3);
    cursor: 'pointer';
}

/* For React Image Gallery */
.image-gallery {
    width: 100%;
    height: auto;
}

.image-gallery-slide img {
    width: 100%;
    height: auto;
    max-height: 80vh;
    object-fit: cover;
    overflow: hidden;
    object-position: center center;
}

.fullscreen .image-gallery-slide img {
    max-height: 100vh;
}

.lazy-load-image-background .black-and-white .lazy-load-image-loaded {
    display: inline !important;
}